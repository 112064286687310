.row-items {
	cursor: pointer;
	margin: 0.5em;
}

.row-items:hover {
	cursor: pointer;
}

.row-items:hover > label {
	color: #9e9e9e !important;
}

.label-custom {
	color: #47516f;
	font-size: 12px;
	vertical-align: middle;
	cursor: pointer;
}
