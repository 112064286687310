.codeSnippet {
	background: #f0f2f4;
	padding: 4px;
	border-radius: 2px;
	color: #555;
	user-select: all; /* Likely future */
}

.copyIcon {
	margin-left: 4px;
	width: 14px;
	cursor: pointer;
	user-select: none;
}

:global .copyTooltip .tooltip {
	font-size: 11px;
}
