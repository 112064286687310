.svg-style {
	margin-left: 0.571em;
	margin-bottom: 0.3em;
	cursor: pointer;
}

.svg-style:hover {
	background-color: #ededed;
}

.custom-model {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0 auto;
}

.custom-header-text {
	font-size: 24px;
	padding: 24px;
	color: #ffffff !important;
	display: inline-block;
}

.custom-header {
	background-color: #043b74;
}

.custom-header > button {
	color: #ffff !important;
}

.custom-header .close-btn {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.modal-error-text {
	color: #cf7272;
	/* margin: 10px; */
}

.modal-info {
	margin-top: 10px;
	margin-bottom: 24px;
	line-height: 24px;
}

.modal-info > label {
	color: #a6a6a6;
}

.modal-info > input {
	color: #898989;
}

.custom-modal-body {
	min-height: 100%;
}

.close-icon {
	float: right;
	margin: 1rem;
	cursor: pointer;
}

.content-height {
	min-height: 100%;
}

.primary-node {
	background: #3a435e;
	color: #fff;
	padding: 0.3em 0.5em;
	margin-left: 0.3em;
	border-radius: 2px;
	user-select: none;
}

/* .modal-close{
	display: inline-block;
} */

/*.close-btn {*/
/*    float: right;*/
/*    font-size: 1.5rem;*/
/*    font-weight: 700;*/
/*    line-height: 1;*/
/*    color: #000;*/
/*    text-shadow: 0 1px 0 #fff;*/
/*    opacity: .5;*/
/*}*/
