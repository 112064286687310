.error {
	color: red;
}

.formComponent {
	margin-top: 30px;
	max-width: 500px;
}

.displayComponent {
	margin-top: 10px;
}

:global .react-datepicker-wrapper {
	display: block;
	width: 100%;
}

:global .react-datepicker__input-container {
	width: 100%;
}

.supplementaryText {
	margin-top: 4px;
	font-size: 1em;
	color: #666;
}

.summary {
	display: flex;
}

.iconContainer {
	margin-right: 1em;
	background: #ccc;
	width: 3em;
	height: 3em;
	flex-shrink: 0;

	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.fakeIcon {
	font-size: 2em;
	font-style: normal;
}

.pageHeader {
	font-weight: 300;
	font-size: 2.18em;
}
