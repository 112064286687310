@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600');

body {
	margin: 0;
	padding: 0;
	color: #555555 !important;
	font-family: -apple-system, 'Source Sans Pro', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px !important;
	line-height: 1.6;
}

html,
#root,
body {
	height: 100% !important;
}

#root > div:first-child {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scrollbox {
	background:
		/* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
		linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
		/* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
		radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

	/* Opera doesn't support this in the shorthand */
	background-attachment: local, local, scroll, scroll;
}

.btn:disabled {
	cursor: not-allowed;
}

.btn {
	line-height: 1.6;
	font-size: 14px;
}

.btn-secondary,
.btn-secondary:disabled {
	background-color: #ed8347;
	padding: 0.575rem 1.143em;
	border: none;
}

.btn-secondary:hover,
.btn-secondary:disabled:hover {
	border: none;
	background-color: #f69a66;
}

.btn-borderless {
	border: none;
	background-color: transparent;
	padding: 0.5em 1.143em;
	color: #3a435e;
}

.btn-borderless:hover,
.btn-borderless:active,
.btn-borderless:not(:disabled):not(.disabled):active {
	color: #3a435e;
	background-color: transparent;
	text-decoration: underline;
}

.btn-borderless:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
	border-radius: 0.25em;
}

.btn-light {
	background-color: transparent;
	border: 1px solid transparent;
	color: #555555;
	min-width: 128px;
	padding: 0.5em 1.143em;
}

.btn-light:hover {
	background-color: transparent;
	border: 1px solid transparent;
}

.btn-light:focus,
.btn-light:active {
	background-color: #eaebf0;
	border: solid 1px #47516f;
	color: #47516f;
	box-shadow: none;
}

.btn-light-custom {
	background-color: transparent;
	border: 1px solid #043b74;
	color: #47516f;
	margin: 0 4px;
}

table {
	max-width: 200em !important;
	margin-bottom: 0 !important;
}

td {
	vertical-align: middle !important;
	padding: 1.143em 2em !important;
}

.table thead th {
	border-bottom: solid 1px #ededed;
	border-top: none;
	padding: 1.714em 2em;
}

th,
td {
	white-space: nowrap;
	text-align: left;
}

td:first-child,
th:first-child {
	padding-left: 2.857em !important;
}

td:last-child,
th:last-child {
	padding-right: 2.857em;
}

.table td {
	border-top: solid 1px #ededed;
}

tr {
	height: 54px;
}

.dropdown-menu {
	border-radius: 0;
	border: none;
	margin: 0;
	padding: 0;
	white-space: nowrap;
}

.dropdown-item:hover {
	color: #fff;
	cursor: pointer;
}

.nav-link:hover {
	color: #fff;
	cursor: pointer;
}

.dropdown-header {
	font-weight: bold;
	font-size: 16px;
	margin: 0.7em 0;
	color: #3a435e;
}

.svg-spacing {
	margin-bottom: 0.2em;
	margin-left: 0.5em;
}

.table-dropdown {
	box-shadow: 0 0 10px 0 rgba(92, 92, 92, 0.3);
	border-radius: 4px;
	margin-top: 0.4em;
}

.custom-button {
	border: #47516f 1px solid !important;
	border-radius: 4px;
	margin-right: 0.5em;
}

.add-button {
	/* border: 1px solid !important; */
	border: 1px !important;
	background-color: #d98b48;
	border-radius: 4px;
	margin-right: 0.5em;
	vertical-align: middle;
}
.vertical-align-middle {
	vertical-align: middle;
}

input[type='checkbox'] {
	cursor: pointer;
}

.custom-link {
	color: #d98b48 !important;
}

.custom-link:hover {
	color: #d98b48 !important;
}

.form-wrapper {
	padding-top: 2em;
	padding-bottom: 2.5rem;
}

.modal-dialog {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0 auto;
	height: 100%;
}

.modal.fade .center-modal {
	transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	-webkit-transition: transform 0.3s ease-out;
	-webkit-transform: translate(0, -50px) !important;
	transform: translate(0, -50px) !important;
}

.modal.fade .modal-dialog {
	transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	-webkit-transition: transform 0.3s ease-out;
	transform: translateX(200px);
	-webkit-transform: translateX(200px);
}

.center-modal {
	position: relative !important;
	width: auto !important;
	pointer-events: none !important;
}

.content-class {
	padding: 1.5rem 1.938em;
}

.modal.show .modal-dialog {
	-webkit-transform: none;
	transform: none;
}

.alert-danger-alert {
	background-color: rgba(216, 141, 141, 0.9);
	border: solid 1px #cf7272;
	color: white;
}

.alert-success {
	color: #fff;
	background-color: rgba(111, 180, 153, 0.9);
	border-color: #4ac594;
}

.alert-link {
	color: #fff !important;
	text-decoration: underline;
}

/*.modal.fade .modal-dialog {*/
/*	transform: translateX(-100px);*/
/*

/*
Create Form Styles
CY: The nested styles are pretty darn specific so probably no need important
Note that the individual form elements (e.g. input, select) need to pre-styled with form-control, using reactstrap elements gives it to you, or you need to manually include the class such as on select elements

*/

.default-padding {
	margin-left: -15px;
	margin-right: -15px;
}

.add-gateway {
	color: #fff;
	text-decoration: underline;
}

.form-control:disabled {
	opacity: 0.65;
	cursor: not-allowed;
}

.create-form-card {
	/* meant to be used with a .row class */
	border-left: 8px solid #0a73b8;
	border-radius: 4px;
	display: block;
	padding: 0;
	margin-bottom: 2.857rem;
}

.create-form-title {
	margin-top: 2.571rem;
	color: #043b74;
	text-align: center;
	margin-bottom: 1em;
	font-weight: 300;
}

.create-form-group {
	/*margin-bottom: 0.8rem;*/
}

.create-label {
	margin-bottom: 0.857em;
}

.list-group-item {
	padding: 1.143rem 2.857rem 0.857rem 2.286rem;
	border: none;
	border-top: 1px solid #e0e0e0;
}

.list-group-item:first-child {
	padding: 1.143rem 2.857rem 0.857rem 2.286rem;
	border: none;
	border-top-left-radius: 0;
}

.location-group-item {
	height: 18.5rem;
	overflow-x: scroll;
}

.create-form-group > label {
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #a6a6a6;
}

.create-form-group > input {
	border: none;
}

.create-form-group > select {
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* Some browsers will not display the caret when using calc, so we put the fallback first */
	background: url('./assets/icons/down-arrow.svg') no-repeat 98.5%;
	background: url('./assets/icons/down-arrow.svg') no-repeat calc(100% - 10px); /*
	Better placement regardless of input width */
}

/* TODO: :focus-within pseudo class doesn't work for Edge */
.create-form-group:focus-within > label {
	color: #0a73b8;
}

.create-form-button {
	margin: 0 auto;
	margin-top: 1rem;
	width: 128px;
	padding: 0.571em;
	height: 40px;
	font-size: 14px;
}

.create-form-button.modal-button {
	width: 100%;
}

.create-form-button:disabled,
.create-form-button:disabled:hover {
	/* need to repeat the colours to override the Bootstrap btn-secondary:disabled styling */
	/*border: #f0c7b0 1px solid;*/
	background-color: #f0c7b0;
	opacity: 0.7;
	cursor: not-allowed;
}

.create-form-cancel {
	margin: 1.143em auto 4.571em auto;
	color: #043b74;
	cursor: pointer;
	text-align: center;
}
.create-form-cancel:hover {
	text-decoration: underline;
}

.create-form-cancel > p {
	font-size: 14px;
}

.create-form-subheader {
	display: table;
	margin: 0 auto;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #272727;
	margin-bottom: 24px;
}

.emphasized-text {
	font-weight: 600;
}

.custom-input {
	padding: 0;
}

.custom-input:invalid,
.custom-input[disabled] {
	opacity: 0.6;
}

/* Alerts */

.success-popup {
	border-radius: 4px;
	border: solid 1px #4ac594;
	background-color: #6fb499;
	margin: 24px 0px 16px 0px;
	/* margin-top: 24px; */
}

.failure-popup {
	border-radius: 4px;
	border: solid 1px #cf7272;
	background-color: rgba(216, 141, 141, 0.9);
	margin: 24px 0px 16px 0px;
}

/* reusable styles for text */

.common-text {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: #ffffff;
}

.common-text.side-modal-header-text {
	font-size: 24px;
	font-weight: 300;
	line-height: 1.6;
}

.common-text u {
	font-weight: 600;
	cursor: pointer;
}

/* for loading spinner */
.sensor-8 {
	width: 3%;
	animation: rotation 2s infinite linear;
}

.clickable-element {
	cursor: pointer;
}
.clickable-element:hover {
	text-decoration: underline;
}

/* coloured dot used for showing offline/online */
.green-dot {
	height: 8px;
	width: 8px;
	background-color: #51b38c;
	border-radius: 50%;
	display: inline-block;
	margin-right: 0.286rem;
}

.red-dot {
	height: 8px;
	width: 8px;
	background-color: #cf7272;
	border-radius: 50%;
	display: inline-block;
	margin-right: 0.286rem;
}

.grey-dot {
	height: 8px;
	width: 8px;
	background-color: #898989;
	border-radius: 50%;
	display: inline-block;
	margin-right: 0.286rem;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
/*
Global placeholder styles
*/
::-webkit-input-placeholder {
	font-style: italic;
	color: #c3c3c3 !important;
}
:-moz-placeholder {
	font-style: italic;
	color: #c3c3c3 !important;
}
::-moz-placeholder {
	font-style: italic;
	color: #c3c3c3 !important;
}
:-ms-input-placeholder {
	font-style: italic;
	color: #c3c3c3 !important;
}
