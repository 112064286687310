@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600);
.App {
	text-align: center;
}

.App-logo {
	-webkit-animation: App-logo-spin infinite 20s linear;
	        animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.row {
	height: 100%;
}

.main-row {
	background-color: #eaebf0;
}

.sidebar-layout {
	width: 64px;
}
.main-page-layout {
	width: calc(100% - 64px);
	overflow-x: hidden;
}

@media (min-width: 667px) {
	.sidebar-layout {
		width: 3.9%;
	}
	.main-page-layout {
		width: 96%;
	}
}

.ConfirmationModal_custom-header-text__kJjEW {
	font-size: 18px;
	font-weight: 600;
	color: #272727 !important;
	display: inline-block;
}

.ConfirmationModal_custom-header__2Zr_c {
}

.ConfirmationModal_custom-header__2Zr_c > button {
	color: #ffff !important;
}

.ConfirmationModal_custom-header__2Zr_c .ConfirmationModal_close-btn__3qXeA {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.ConfirmationModal_modal-error-text__1Rft6 {
	color: #cf7272;
	/* margin: 10px; */
}

.ConfirmationModal_modal-info__Dj_v9 {
	margin-top: 10px;
	margin-bottom: 24px;
	line-height: 24px;
}

.ConfirmationModal_modal-info__Dj_v9 > label {
	color: #a6a6a6;
}

.ConfirmationModal_modal-info__Dj_v9 > input {
	color: #898989;
}

.ConfirmationModal_custom-modal-body__2Yvv7 {
	height: 100vh;
}

.ConfirmationModal_close-icon__tl2vH {
	float: right;
	margin: 1rem;
	cursor: pointer;
}

.ConfirmationModal_modal-body__xFofJ > p {
	color: #555555;
	font-size: 16px;
}

.ConfirmationModal_modal-body__xFofJ {
	margin-bottom: 1.5rem;
}

.ConfirmationModal_modal-footer__30Yo9 > button {
	float: right;
}

.ConfirmationModal_cancel-button__1iEoJ {
	margin-right: 0.5em;
	border: 1px solid #555555 !important;
}

.ConfirmationModal_cancel-button__1iEoJ:hover {
	background-color: #f7f7f7 !important;
}

.ConfirmationModal_confirm-button__YbOJ- {
	padding: 0.5em 1.143em !important;
	min-width: 128px;
}

/* Styles for create gateway */

.CreateGateway_load-page-text__3GIBW {
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: center;
	color: #3a435e;
}

.CreateGateway_gateway-success-rectangle__ASHDe {
	width: 333px;
	border-radius: 4px;
	background-color: #ffffff;
	display: block;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
}

.CreateGateway_gateway-success-label__3ZNBz {
	font-family: Source Sans Pro;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: center;
	font-size: 14px;
	font-weight: 600;
	line-height: 2.29;
	color: #47516f;
	margin-left: 24px;
	text-align: left;
}

.CreateGateway_gateway-success-vars__2zKM9 {
	font-family: Source Sans Pro;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: center;
	font-size: 14px;
	font-weight: normal;
	line-height: 2.29;
	color: #47516f;
	text-align: right;
	margin-right: 24px;
}

.CreateGateway_download-button__eJv-R {
	border-radius: 0px 0px 4px;
	background-color: #ed8347;
	margin-left: 15px;
	margin-right: 15px;
	height: 40px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	color: #ffffff;
	font-family: Source Sans Pro;
	cursor: pointer;
}

.CreateGateway_download-button__eJv-R:hover {
	background-color: #f69a66;
}

.LoadingIndicator_div-wrapper__1LgK_ {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 70vh;
}

.Location_location-name__1Sj07 {
	font-size: 35px;
	margin-top: 32px;
	margin-bottom: 20.5px !important;
	color: #043b74 !important;
}

.Location_tab-style__apIq_ {
	cursor: pointer;
	color: #a6a6a6 !important;
}

.Location_nav-link-active__1lMY7 {
	color: #495057 !important;
	box-shadow: inset 0 -4px 0 -1px #3a435e;
}

.Location_custom-nav-item__XFQ63 {
	margin-right: 24px;
	font-size: 1.286em;
}

.SearchBar_search__1AGeV {
	border-radius: 4px !important;
	border: solid 1px #c3c3c3;
	max-width: 17.125em !important;
	background-color: transparent !important;
	padding-left: 2px;
	height: 2.857em;
	transition: border 0.25s;
	margin: 0 4px;
}

.SearchBar_search-button__2hVot {
	width: 2.857em;
	height: 2.857em;
}

.SearchBar_custom-input__nfAmL {
	flex-grow: 2 !important;
	border: none !important;
	background-color: transparent !important;
	border-radius: 20px !important;
	max-width: 12em !important;
	padding-top: 0.7rem;
	padding-left: 4px;
	height: 1.9em !important;
	box-shadow: none !important;
	margin-top: 0.2em;
}

.SearchBar_custom-input__nfAmL::-webkit-input-placeholder {
	color: #c3c3c3 !important;
}

.SearchBar_custom-input__nfAmL::-ms-input-placeholder {
	color: #c3c3c3 !important;
}

.SearchBar_custom-input__nfAmL::placeholder {
	color: #c3c3c3 !important;
}

.SearchBar_custom-input__nfAmL:focus {
	outline: none !important;
}

.SearchBar_search-logo__35_bh {
	margin-bottom: 0.2em;
}

.SearchBar_search-logo-focus__2oOoy > g > g {
	stroke: #47516f;
}

.SearchBar_custom-button__37Ydu {
	padding: 0 1.25em 0 0 !important;
	background-color: transparent !important;
	border: none !important;
	border-radius: 50px !important;
}

.SearchBar_search-keyword__JDg0n {
	background-color: white;
	padding: 0.7em 1.143em 0.7em 1.286em;
	border-radius: 30px;
	margin-right: 0.75em;
}

.SearchBar_search-keyword__JDg0n:first-child {
	margin-left: 1.156em;
}

.SearchBar_close-button__xI-fb {
	cursor: pointer;
	margin: 0 0 0 0.5em;
	padding: 0.2em 0.5em;
	border-radius: 50%;
}

.SearchBar_close-button__xI-fb:hover {
	background-color: rgba(32, 33, 36, 0.059);
}

.SearchBar_close-button__xI-fb > svg {
	margin-bottom: 0.2em;
}

.SearchBar_button-div__3zBZe {
	margin-bottom: 0.4em;
}

.SearchBar_auto-suggest-dropdown__125xO {
	cursor: pointer;
	padding: 0.5em;
}

.SearchBar_custom-dropdown__1xlYG {
	box-shadow: 0 0 10px 0 rgba(92, 92, 92, 0.3) !important;
}

/*.auto-suggest-dropdown:hover {*/
/*	background-color: #2a68a8;*/
/*	color: #ffffff;*/
/*}*/

.SearchBar_auto-suggest-dropdown__125xO:first-child:hover {
	border-radius: 4px 4px 0 0;
}

.SearchBar_auto-suggest-dropdown__125xO:last-child:hover {
	border-radius: 0 0 4px 4px;
}

.ManageColumnsToggle_row-items__2hGeA {
	cursor: pointer;
	margin: 0.5em;
}

.ManageColumnsToggle_row-items__2hGeA:hover {
	cursor: pointer;
}

.ManageColumnsToggle_row-items__2hGeA:hover > label {
	color: #9e9e9e !important;
}

.ManageColumnsToggle_label-custom__1Bl8l {
	color: #47516f;
	font-size: 12px;
	vertical-align: middle;
	cursor: pointer;
}

.ListViewWrapper_custom-table__14vwg {
	background-color: white;
	color: #555555 !important;
	border-radius: 4px;
}

.ListViewWrapper_pagination-custom__edq2c {
	cursor: pointer;
	margin-left: 1.4em;
	width: 1.5em;
	height: 1.5em;
	border-radius: 1px;
}

.ListViewWrapper_pagination-custom__edq2c:hover {
	background-color: #f6f6f6;
}

.ListViewWrapper_active-custom__1UYwT {
	background-color: #3a435e;
}

.ListViewWrapper_active-custom__1UYwT:hover {
	background-color: #3a435e !important;
}
.ListViewWrapper_active-custom__1UYwT > a {
	color: #fff !important;
}

.ListViewWrapper_link__2dFLv {
	width: inherit;
	height: inherit;
	color: #898989;
}

.ListViewWrapper_link__2dFLv:hover {
	text-decoration: none;
}

.ListViewWrapper_rotate-right__2-WFD {
	transform: rotate(270deg);
}

.ListViewWrapper_rotate-left__2NrBV {
	transform: rotate(90deg);
}

.ListViewWrapper_add-button__2FeFp {
	padding: 0.571rem 1.143rem;
	line-height: 1.6;
	white-space: nowrap;
}

p {
	font-weight: 300;
	font-size: 2.18em;
	margin-bottom: 0 !important;
	line-height: 1;
}

.ListViewWrapper_row-custom__1bHHP {
	margin-top: 2.75em;
}

.ListViewWrapper_custom-list__3Ubmd {
	margin-top: 2.929em !important;
}

.ListViewWrapper_custom-list__3Ubmd > li {
	padding-left: 0 !important;
}

.ListViewWrapper_table-header__2w4bY {
	cursor: pointer !important;
}

.ListViewWrapper_filtered-text__3C7Ae {
	font-size: 12px;
	margin: 0;
	font-weight: 500;
}

.ListViewWrapper_filtered-list__28ERJ {
	background-color: #a29f9c;
	list-style-type: none;
	margin-right: 1em;
	cursor: pointer;
	border-radius: 2px;
	padding: 2px 8px;
	color: white;
	font-weight: 600;
}

.ListViewWrapper_cancel-filtered-keyword__2E9MK {
	transform: scale(0.6);
}

tbody tr:hover {
	transition: background-color 0.3s ease;
}

tbody tr:hover {
	background-color: #f7f7f7;
}

.ListViewWrapper_booleanFilter__1yaij {
	padding: 0.5em 1.143em;
	margin-top: 6px;
}

.ListViewWrapper_booleanFilter__1yaij input {
	margin-right: 0.25em;
}

.ListViewWrapper_drag-over__FJ8Q5 {
	border-left: 1px solid #6b79a4;
}

.Node_RowContainer__GbWUS {
	display: flex;
}

.Node_Rectangle__28jA7 {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex: 1 1 24px;
	border-radius: 4px;
	padding: 8px;
	background-color: #ffffff;
	margin: 0 4px 8px 4px;
}

@media only screen and (max-device-width: 890px) {
	.Node_Rectangle__28jA7 {
		text-align: center;
	}
}

.Node_Rectangle__28jA7:first-child {
	margin-left: 0;
}

.Node_status-label__3Igio {
	font-family: Source Sans Pro;
	font-size: 14px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.14;
	letter-spacing: normal;
}

.Node_Offline-Copy-2__PYGsQ {
	height: 16px;
	width: 10.357rem;
	color: #e27e7e;
}

.Node_Online__6sKE5 {
	height: 16px;
	width: 10.357rem;
	color: #4ac594;
}

.Node_Never-Seen__36eBC {
	height: 16px;
	width: 10.357rem;
	color: #858b9d;
}
.Node_Total__rbxbo {
	color: #47516f;
}

.Node_-copy__3fWf6 {
	font-family: Source Sans Pro;
	font-size: 20px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #555555;
	display: inline-flex;
	align-items: center;
}

.Node_online-number__wnB83 {
	color: #4ac594;
	font-weight: 600;
	margin: 0 auto;
}

.Node_never-seen-number__2fXgd {
	color: #858b9d;
	font-weight: 600;
	margin: 0 auto;
}

.Node_offline-number__3seEL {
	color: #e27e7e;
	font-weight: 600;
	margin: 0 auto;
}

.Node_container-width__1sp0v {
	width: 60%;
}

.Node_selectedNodeStatus__khwmR {
	border: 1px solid #043b74;
}

.CodeSnippet_codeSnippet__1zpFc {
	background: #f0f2f4;
	padding: 4px;
	border-radius: 2px;
	color: #555;
	-webkit-user-select: all;
	   -moz-user-select: all;
	    -ms-user-select: all;
	        user-select: all; /* Likely future */
}

.CodeSnippet_copyIcon__3JLPR {
	margin-left: 4px;
	width: 14px;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.copyTooltip .tooltip {
	font-size: 11px;
}

.ListNodes_svg-style__3Hhzz {
	margin-left: 0.571em;
	margin-bottom: 0.3em;
	cursor: pointer;
}

.ListNodes_svg-style__3Hhzz:hover {
	background-color: #ededed;
}

.ListNodes_custom-model__2nUz0 {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0 auto;
}

.ListNodes_custom-header-text__2eCca {
	font-size: 24px;
	padding: 24px;
	color: #ffffff !important;
	display: inline-block;
}

.ListNodes_custom-header__29ePW {
	background-color: #043b74;
}

.ListNodes_custom-header__29ePW > button {
	color: #ffff !important;
}

.ListNodes_custom-header__29ePW .ListNodes_close-btn__SlCe5 {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.ListNodes_modal-error-text__1qnmx {
	color: #cf7272;
	/* margin: 10px; */
}

.ListNodes_modal-info__3_vVk {
	margin-top: 10px;
	margin-bottom: 24px;
	line-height: 24px;
}

.ListNodes_modal-info__3_vVk > label {
	color: #a6a6a6;
}

.ListNodes_modal-info__3_vVk > input {
	color: #898989;
}

.ListNodes_custom-modal-body__235Fq {
	min-height: 100%;
}

.ListNodes_close-icon__3lU8r {
	float: right;
	margin: 1rem;
	cursor: pointer;
}

.ListNodes_content-height__2sE4F {
	min-height: 100%;
}

.ListNodes_primary-node__2WkgQ {
	background: #3a435e;
	color: #fff;
	padding: 0.3em 0.5em;
	margin-left: 0.3em;
	border-radius: 2px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

/* .modal-close{
	display: inline-block;
} */

/*.close-btn {*/
/*    float: right;*/
/*    font-size: 1.5rem;*/
/*    font-weight: 700;*/
/*    line-height: 1;*/
/*    color: #000;*/
/*    text-shadow: 0 1px 0 #fff;*/
/*    opacity: .5;*/
/*}*/

.modal_custom-header-text__2pfVw {
	font-size: 24px;
	padding: 24px;
	color: #ffffff !important;
	display: inline-block;
}

.modal_custom-header__2JxsV {
	background-color: #043b74;
}

.modal_custom-header__2JxsV > button {
	color: #ffff !important;
}

.modal_custom-header__2JxsV .modal_close-btn__2TaZW {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.modal_modal-error-text__1jNeC {
	color: #cf7272;
	/* margin: 10px; */
}

.modal_modal-info__3466_ {
	margin-top: 10px;
	margin-bottom: 24px;
	line-height: 24px;
}

.modal_modal-info__3466_ > label {
	color: #a6a6a6;
}

.modal_modal-info__3466_ > input {
	color: #898989;
}

.modal_custom-modal-body__3MFiE {
	height: 100vh;
}

.modal_close-icon__1WJTQ {
	float: right;
	margin: 1rem;
	cursor: pointer;
}

.ListGateways_trash-icon__3h3aJ {
	transform: scale3d(0.6, 0.6, 0.6);
	fill: red;
}

.User_create-link__3aN2L {
	color: forestgreen;
}

.User_delete-button__3-QYP,
.User_update-link__21r2R {
	color: peru;
}

.User_wrapper__te6AB {
	padding-top: 2em;
	/*padding-bottom: 2.5rem;*/
}

.User_form-card__31V7k {
	margin-top: 2.043rem;
	padding-bottom: 2em;
}

.User_purple__2ReoC {
	color: mediumslateblue;
}

.User_disabled-link__2_Hlt {
	pointer-events: none;
}

.User_dropdown-link__3RRXi,
.User_dropdown-link__3RRXi:hover {
	text-decoration: none;
}

.User_dropdownmenu-item__3bVK4,
.User_dropdownmenu-item__3bVK4:hover {
	color: #043b74 !important;
}

.User_dropdownmenu-item__3bVK4:active {
	background-color: inherit !important;
}

.User_dropdownmenu__2H3_u {
	box-shadow: 0 0 10px 0 rgba(92, 92, 92, 0.15);
}

.User_location-item__3j0_K {
	list-style-type: none;
	border-radius: 4px;
	border: solid 1px #0a73b8;
	padding: 0.75rem;
	color: #0a73b8;
	margin-top: 0.5rem;
}

.User_locations-list__1a57i {
	padding-left: 0 !important;
}

.User_clear-icon___5qG2,
.User_down-icon__Ly84D,
.User_location-clear-icon__3miYV {
	margin-left: 0.5em;
	cursor: pointer;
}

.User_clear-icon___5qG2 > path {
	fill: #555;
}

.User_clear-icon___5qG2:hover > path {
	fill: #ed8347;
}

.User_location-clear-icon__3miYV > path {
	fill: #0a73b8;
}
.User_location-clear-icon__3miYV:hover > path {
	fill: #043b74;
}

.User_down-icon__Ly84D:hover > path {
	fill: #ed8347;
	stroke: #ed8347;
}

.User_custom-dropdown-placeholder__3J3Av {
	cursor: pointer !important;
}

.User_custom-dropdown-placeholder__3J3Av > div > div:not(.User_multi-select-dropdown__aXdYA) {
	color: #c3c3c3 !important;
}

.User_multi-select-dropdown__aXdYA {
	color: #2a68a8;
	padding: 0.964rem 1.643rem;
}

.User_multi-select-dropdown__aXdYA:hover {
	background-color: #0a73b8;
	color: #fff;
	cursor: pointer;
}

.User_role-disabled__aseX4 {
	background-color: rgb(242, 242, 242);
	cursor: not-allowed;
	color: rgb(204, 204, 204);
}

.User_role-disabled__aseX4:hover {
	cursor: not-allowed;
}

.User_custom-input-search__3G-az {
	line-height: inherit;
	/*width: calc(0ch + 5px);*/
	border: none;
	margin-left: 5px;
	background: transparent;
	font-size: smaller;
}

.User_custom-input-search__3G-az {
	outline: 0;
}

ins {
	font-size: x-small;
	text-transform: uppercase;
	text-decoration: none;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(204, 204, 204);
	border-image: none;
	border-image: initial;
	border-radius: 2px;
	padding: 0px 3px;
	margin-left: 0.4em;
}

.User_alert-link__2WT-r {
	color: #fff !important;
	text-decoration: underline;
}

.User_placeholder-text__yxfL6 {
	font-size: 16px;
	color: #c3c3c3;
	margin-top: 1.344rem;
}

.User_search-and-toggle__2wsMR {
	display: flex;
	flex-direction: column;
	padding: 0.75rem;
}

.User_dropdown-buttons__28zLG {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.User_dropdown-buttons__28zLG div {
	margin: 10px 0 0 0;
	font-weight: 600;
}

.Room_boxLayout__1iJdv {
	margin: 6em 6em 0 6em;
	padding-bottom: 2em;
}

.Room_baseLayout__9nuJ2 {
	background-color: #f2f4f8;
	padding: 6em;
}

.Room_deviceTitle__3IGbS {
	text-align: left;
}

.Room_nodeLayout__3MNKM {
	border: 1px solid #2e2e2e;
	background-color: #f5ebd5;
}

.Room_airconLayout__18aPx {
	border: 1px solid #2e2e2e;
	padding: 8em;
	background-color: #a3d9e8;
}

.Room_cardLayout__K6Hz3 {
	margin-top: 2em;
}

.Room_cardBodyLayout__1yvGX {
	padding: 2em !important;
}

.FirmwareUpdateList_error__1Fnr_ {
	color: red;
}

.FirmwareUpdateList_formComponent__1mJUd {
	margin-top: 30px;
}

.FirmwareUpdateList_displayComponent__2LBDk {
	margin-top: 10px;
}

.FirmwareUpdateList_react-datepicker__input-container__2yug8 {
	width: 100%;
}

.FirmwareUpdateList_selected__orGDg {
	background-color: #e5f1ff;
}

/* side panel */
.FirmwareUpdateList_itemInfo__jd76g {
	/* attempt at using variables, this will eventually be in a react design system */
	--unit-xs: 4px;
	--unit-s: 8px;
	--unit-m: 16px;
	--unit-l: 24px;
	background: white;
	padding: var(--unit-s);
}

@media (min-width: 992px) {
	.FirmwareUpdateList_itemInfo__jd76g {
		padding: var(--unit-s) var(--unit-m);
		/* hacky MVP for the new UI principles we want to implement, when we implement the design system we will refactor all existing layouts all together and these won't be needed anymore */
		/* hack for breaking out of global 3.5em margin on all layouts container */
		width: calc(100% + 3.5em);
		/* another hack for positioning the side panel, it will never be aligned this way! */
		margin-top: 126px;
	}
}

.FirmwareUpdateList_itemInfo__jd76g h2 {
	font-size: 26px;
	font-weight: normal;
	color: #757575; /* AA 4.5 contrast ratio */
	margin-bottom: 0.25em; /* scales with text */
}

.FirmwareUpdateList_itemInfo__jd76g h3 {
	font-size: 18px;
	font-weight: normal;
	color: #757575; /* AA 4.5 contrast ratio */
	margin-bottom: 0.25em; /* scales with text */
}

.FirmwareUpdateList_itemInfo__jd76g p {
	font-size: 14px;
	font-weight: normal;
}

.FirmwareUpdateList_itemInfo__jd76g dt {
	color: #757575; /* AA 4.5 contrast ratio */
	border-bottom: 1px solid #c3c3c3;
}

.FirmwareUpdateList_itemInfo__jd76g dd {
	font-size: 18px;
}

.FirmwareUpdateList_itemInfo__jd76g .FirmwareUpdateList_smallText__9YfQ6 {
	font-size: 14px;
}

.FirmwareUpdateList_update__35QTI {
	margin-top: var(--unit-m);
	border-bottom: 1px solid #c3c3c3;
	padding-bottom: var(--unit-m);
}

.FirmwareUpdateList_update__35QTI dt,
.FirmwareUpdateList_update__35QTI dd {
	font-size: 14px;
	margin: 0;
	border-bottom: none;
}

.FirmwareUpdateList_update__35QTI dd {
	font-size: 12px;
}

.FirmwareUpdateList_nodesList__1yEKo {
	padding-left: 0;
	list-style-type: none;
}

.FirmwareUpdateList_nodesList__1yEKo li {
	font-size: 87.5%;
	color: #e83e8c;
	word-wrap: break-word;
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.FirmwareUpdateList_cancelButton__24ybj {
	font-size: 12px !important; /* make smaller */
	margin-top: var(--unit-s);
	margin-bottom: var(--unit-s);
}

.FirmwareUpdateList_toast__Ac2tz {
	position: fixed; /* always on top of screen */
	top: 40px;
	right: 20px;
	min-width: 250px;
}

.FirmwareUpdateList_toast__Ac2tz .FirmwareUpdateList_toast-body__1Kg4v {
	background-color: #e5f1ff;
}

.FirmwareUpdateList_updateLogLink__3OC2w {
	color: #d98b48;
	cursor: pointer;
	display: inline-block;
}

.FirmwareUpdateList_updateLog__3LlVT {
	resize: none;
	font-size: 87.5%;
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	width: 100%;
	height: 300px;
	border: 1px solid #c3c3c3;
	overflow-y: scroll;
	background-color: #eaebf0;
}

.FirmwareUpdateList_updateLog__3LlVT:focus {
	outline: none;
	border: 1px solid #000;
}

.FirmwareUpload_input-file-row__27n4T {
	margin-bottom: 1em;
}

.FirmwareUpload_submit-button__jGTVT {
	margin-top: 2em;
	margin-left: auto;
}

.Firmware_container__1kXmo {
	margin: 2em 1em 0 1em;
}

.Firmware_nav-link-active__3H7ys {
	color: #495057;
	background-color: #fff;
	border-color: #dee2e6 #dee2e6 #fff !important;
}

.Firmware_tab-style__srzfm {
	cursor: pointer;
}

.FirmwareDocuments_wrapper__3O9Ye {
	padding-top: 2em;
}

.FirmwareDocuments_table-head__2-rM9 {
	background-color: #ececec;
}

.FirmwareUpdateSchedule_error__2NIlA {
	color: red;
}

.FirmwareUpdateSchedule_formComponent__Y3jtA {
	margin-top: 30px;
	max-width: 500px;
}

.FirmwareUpdateSchedule_displayComponent__1_Y0L {
	margin-top: 10px;
}

.react-datepicker-wrapper {
	display: block;
	width: 100%;
}

.react-datepicker__input-container {
	width: 100%;
}

.FirmwareUpdateSchedule_supplementaryText__3fYRP {
	margin-top: 4px;
	font-size: 1em;
	color: #666;
}

.FirmwareUpdateSchedule_summary__1iY0u {
	display: flex;
}

.FirmwareUpdateSchedule_iconContainer__1dlkz {
	margin-right: 1em;
	background: #ccc;
	width: 3em;
	height: 3em;
	flex-shrink: 0;

	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.FirmwareUpdateSchedule_fakeIcon__2aNKn {
	font-size: 2em;
	font-style: normal;
}

.FirmwareUpdateSchedule_pageHeader__3pgNp {
	font-weight: 300;
	font-size: 2.18em;
}

.ListLocations_healthy__3tu5- {
	color: #51b38c;
}

.ListLocations_warning__3NLnM {
	color: #e8b754;
}

.ListLocations_critical__3Gtf_ {
	color: #cf7272;
}

.ListLocations_neverSeen__1vBHu {
	color: #555555;
}

.ListLocations_add-button-contents__2GuME {
	margin: 0 5px 0 5px;
	vertical-align: bottom;
}

.ListLocations_dropdownmenu__2l5ax {
	width: 189px;
	border-radius: 4px;
	box-shadow: 0 0 10px 0 rgba(92, 92, 92, 0.15);
}

.ListLocations_dropdownmenu-item__3_til {
	text-align: center;
	padding: 0.571em 1.714em !important;
}
.ListLocations_dropdownmenu-item__3_til:hover {
	background-color: #043b74 !important;
}

.ListLocations_gateway-cell__2l6Jy:hover {
	cursor: pointer;
	text-decoration: underline;
}

.ListLocations_node-status__2Jfkn:hover {
	cursor: pointer;
	text-decoration: underline;
}
/* .menu-text {
	width: 79px;
	height: 24px;
	font-family: Source Sans Pro;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: #47516f;
  } */

.MainPage_mainPage__YvDCw {
	min-height: 100%;
}

@media (min-width: 667px) {
	.MainPage_mainPage__YvDCw {
		margin: 0px 3.5em;
	}
}

.SideNav_custom-navbar__2aYsc {
	background-color: #043b74;
	padding-left: 0 !important;
	width: 56px;
}

.SideNav_custom-nav-item__2enf1 {
	width: 100%;
	border-bottom: 0.5px solid rgba(255, 255, 255, 0.19);
	padding: auto;
	vertical-align: center;
}

.SideNav_custom-nav-item__2enf1:hover {
	background-color: #234e7f;
	color: #fff !important;
}

.SideNav_custom-dropdown__34lNZ {
	top: 0 !important;
	left: 100% !important;
	box-shadow: 0 0 8px 0 rgba(92, 92, 92, 0.15);
}

.SideNav_custom-navlink__NJvTV {
	padding: 1rem !important;
}

.SideNav_custom-dropdown-link__161Jd {
	color: #5c5c5c !important;
	padding: 0.813em 1.5em !important;
}

.SideNav_custom-dropdown-link__161Jd:hover {
	color: #fff !important;
}

.SideNav_custom-dropdown-link__161Jd:hover {
	color: #ea6e28 !important;
	cursor: pointer;
}

.SideNav_custom-dropdown-header__17dVY {
	font-weight: bold !important;
	font-size: 14px !important;
	color: #5c5c5c !important;
}

.CustomGreetings_logo__3wRrl {
	width: 90px;
	height: 54.9px;
	margin-left: 2em;
}

.CustomGreetings_custom-navbar__d07xQ {
	background-color: white;
	padding: 0 !important;
	margin: 0 !important;
	box-shadow: 0 0 6px 0 rgba(92, 92, 92, 0.18);
}

.CustomGreetings_custom-button__1eIbd {
	padding: 1.6em 1.3em 1.9em 1.3em !important;
	text-align: center;
	border: none !important;
	border-bottom: 0.5px solid rgba(255, 255, 255, 0.19) !important;
	background-color: #043b74 !important;
	color: white !important;
	border-radius: 0 !important;
	font-size: 1rem !important;
}

.CustomGreetings_custom-button__1eIbd:hover {
	background-color: #234e7f !important;
}

.CustomGreetings_custom-button__1eIbd:focus {
	box-shadow: none !important;
}

.CustomGreetings_down-arrow__8Q7e3 {
	margin-left: 1.844em;
	margin-right: 1.5em;
	margin-bottom: 0.4em;
}

.CustomGreetings_custom-dropdown__14K7v {
	box-shadow: 0 10px 10px 0 rgba(92, 92, 92, 0.08);
}

.CustomGreetings_username__3PgEs {
	font-size: 16px;
	padding: 1.7em 1.5em !important;
	color: #6d7278;
}

.CustomGreetings_username__3PgEs:hover {
	color: #47516f !important;
}

.QuickNavigateLocations_quickNavigateLocation__2jVNn {
	width: 300px;
}
.QuickNavigateLocations_quickNavigateLocationContainer__101f6 {
	display: flex;
	align-items: center;
	margin-left: 3em;
}

body {
	margin: 0;
	padding: 0;
	color: #555555 !important;
	font-family: -apple-system, 'Source Sans Pro', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px !important;
	line-height: 1.6;
}

html,
#root,
body {
	height: 100% !important;
}

#root > div:first-child {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scrollbox {
	background:
		/* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
		linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
		/* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
		radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

	/* Opera doesn't support this in the shorthand */
	background-attachment: local, local, scroll, scroll;
}

.btn:disabled {
	cursor: not-allowed;
}

.btn {
	line-height: 1.6;
	font-size: 14px;
}

.btn-secondary,
.btn-secondary:disabled {
	background-color: #ed8347;
	padding: 0.575rem 1.143em;
	border: none;
}

.btn-secondary:hover,
.btn-secondary:disabled:hover {
	border: none;
	background-color: #f69a66;
}

.btn-borderless {
	border: none;
	background-color: transparent;
	padding: 0.5em 1.143em;
	color: #3a435e;
}

.btn-borderless:hover,
.btn-borderless:active,
.btn-borderless:not(:disabled):not(.disabled):active {
	color: #3a435e;
	background-color: transparent;
	text-decoration: underline;
}

.btn-borderless:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
	border-radius: 0.25em;
}

.btn-light {
	background-color: transparent;
	border: 1px solid transparent;
	color: #555555;
	min-width: 128px;
	padding: 0.5em 1.143em;
}

.btn-light:hover {
	background-color: transparent;
	border: 1px solid transparent;
}

.btn-light:focus,
.btn-light:active {
	background-color: #eaebf0;
	border: solid 1px #47516f;
	color: #47516f;
	box-shadow: none;
}

.btn-light-custom {
	background-color: transparent;
	border: 1px solid #043b74;
	color: #47516f;
	margin: 0 4px;
}

table {
	max-width: 200em !important;
	margin-bottom: 0 !important;
}

td {
	vertical-align: middle !important;
	padding: 1.143em 2em !important;
}

.table thead th {
	border-bottom: solid 1px #ededed;
	border-top: none;
	padding: 1.714em 2em;
}

th,
td {
	white-space: nowrap;
	text-align: left;
}

td:first-child,
th:first-child {
	padding-left: 2.857em !important;
}

td:last-child,
th:last-child {
	padding-right: 2.857em;
}

.table td {
	border-top: solid 1px #ededed;
}

tr {
	height: 54px;
}

.dropdown-menu {
	border-radius: 0;
	border: none;
	margin: 0;
	padding: 0;
	white-space: nowrap;
}

.dropdown-item:hover {
	color: #fff;
	cursor: pointer;
}

.nav-link:hover {
	color: #fff;
	cursor: pointer;
}

.dropdown-header {
	font-weight: bold;
	font-size: 16px;
	margin: 0.7em 0;
	color: #3a435e;
}

.svg-spacing {
	margin-bottom: 0.2em;
	margin-left: 0.5em;
}

.table-dropdown {
	box-shadow: 0 0 10px 0 rgba(92, 92, 92, 0.3);
	border-radius: 4px;
	margin-top: 0.4em;
}

.custom-button {
	border: #47516f 1px solid !important;
	border-radius: 4px;
	margin-right: 0.5em;
}

.add-button {
	/* border: 1px solid !important; */
	border: 1px !important;
	background-color: #d98b48;
	border-radius: 4px;
	margin-right: 0.5em;
	vertical-align: middle;
}
.vertical-align-middle {
	vertical-align: middle;
}

input[type='checkbox'] {
	cursor: pointer;
}

.custom-link {
	color: #d98b48 !important;
}

.custom-link:hover {
	color: #d98b48 !important;
}

.form-wrapper {
	padding-top: 2em;
	padding-bottom: 2.5rem;
}

.modal-dialog {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0 auto;
	height: 100%;
}

.modal.fade .center-modal {
	transition: transform 0.3s ease-out;
	-webkit-transition: transform 0.3s ease-out;
	transform: translate(0, -50px) !important;
}

.modal.fade .modal-dialog {
	transition: transform 0.3s ease-out;
	-webkit-transition: transform 0.3s ease-out;
	transform: translateX(200px);
	-webkit-transform: translateX(200px);
}

.center-modal {
	position: relative !important;
	width: auto !important;
	pointer-events: none !important;
}

.content-class {
	padding: 1.5rem 1.938em;
}

.modal.show .modal-dialog {
	transform: none;
}

.alert-danger-alert {
	background-color: rgba(216, 141, 141, 0.9);
	border: solid 1px #cf7272;
	color: white;
}

.alert-success {
	color: #fff;
	background-color: rgba(111, 180, 153, 0.9);
	border-color: #4ac594;
}

.alert-link {
	color: #fff !important;
	text-decoration: underline;
}

/*.modal.fade .modal-dialog {*/
/*	transform: translateX(-100px);*/
/*

/*
Create Form Styles
CY: The nested styles are pretty darn specific so probably no need important
Note that the individual form elements (e.g. input, select) need to pre-styled with form-control, using reactstrap elements gives it to you, or you need to manually include the class such as on select elements

*/

.default-padding {
	margin-left: -15px;
	margin-right: -15px;
}

.add-gateway {
	color: #fff;
	text-decoration: underline;
}

.form-control:disabled {
	opacity: 0.65;
	cursor: not-allowed;
}

.create-form-card {
	/* meant to be used with a .row class */
	border-left: 8px solid #0a73b8;
	border-radius: 4px;
	display: block;
	padding: 0;
	margin-bottom: 2.857rem;
}

.create-form-title {
	margin-top: 2.571rem;
	color: #043b74;
	text-align: center;
	margin-bottom: 1em;
	font-weight: 300;
}

.create-form-group {
	/*margin-bottom: 0.8rem;*/
}

.create-label {
	margin-bottom: 0.857em;
}

.list-group-item {
	padding: 1.143rem 2.857rem 0.857rem 2.286rem;
	border: none;
	border-top: 1px solid #e0e0e0;
}

.list-group-item:first-child {
	padding: 1.143rem 2.857rem 0.857rem 2.286rem;
	border: none;
	border-top-left-radius: 0;
}

.location-group-item {
	height: 18.5rem;
	overflow-x: scroll;
}

.create-form-group > label {
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #a6a6a6;
}

.create-form-group > input {
	border: none;
}

.create-form-group > select {
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* Some browsers will not display the caret when using calc, so we put the fallback first */
	background: url(/static/media/down-arrow.787b0efb.svg) no-repeat 98.5%;
	background: url(/static/media/down-arrow.787b0efb.svg) no-repeat calc(100% - 10px); /*
	Better placement regardless of input width */
}

/* TODO: :focus-within pseudo class doesn't work for Edge */
.create-form-group:focus-within > label {
	color: #0a73b8;
}

.create-form-button {
	margin: 0 auto;
	margin-top: 1rem;
	width: 128px;
	padding: 0.571em;
	height: 40px;
	font-size: 14px;
}

.create-form-button.modal-button {
	width: 100%;
}

.create-form-button:disabled,
.create-form-button:disabled:hover {
	/* need to repeat the colours to override the Bootstrap btn-secondary:disabled styling */
	/*border: #f0c7b0 1px solid;*/
	background-color: #f0c7b0;
	opacity: 0.7;
	cursor: not-allowed;
}

.create-form-cancel {
	margin: 1.143em auto 4.571em auto;
	color: #043b74;
	cursor: pointer;
	text-align: center;
}
.create-form-cancel:hover {
	text-decoration: underline;
}

.create-form-cancel > p {
	font-size: 14px;
}

.create-form-subheader {
	display: table;
	margin: 0 auto;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #272727;
	margin-bottom: 24px;
}

.emphasized-text {
	font-weight: 600;
}

.custom-input {
	padding: 0;
}

.custom-input:invalid,
.custom-input[disabled] {
	opacity: 0.6;
}

/* Alerts */

.success-popup {
	border-radius: 4px;
	border: solid 1px #4ac594;
	background-color: #6fb499;
	margin: 24px 0px 16px 0px;
	/* margin-top: 24px; */
}

.failure-popup {
	border-radius: 4px;
	border: solid 1px #cf7272;
	background-color: rgba(216, 141, 141, 0.9);
	margin: 24px 0px 16px 0px;
}

/* reusable styles for text */

.common-text {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: #ffffff;
}

.common-text.side-modal-header-text {
	font-size: 24px;
	font-weight: 300;
	line-height: 1.6;
}

.common-text u {
	font-weight: 600;
	cursor: pointer;
}

/* for loading spinner */
.sensor-8 {
	width: 3%;
	-webkit-animation: rotation 2s infinite linear;
	        animation: rotation 2s infinite linear;
}

.clickable-element {
	cursor: pointer;
}
.clickable-element:hover {
	text-decoration: underline;
}

/* coloured dot used for showing offline/online */
.green-dot {
	height: 8px;
	width: 8px;
	background-color: #51b38c;
	border-radius: 50%;
	display: inline-block;
	margin-right: 0.286rem;
}

.red-dot {
	height: 8px;
	width: 8px;
	background-color: #cf7272;
	border-radius: 50%;
	display: inline-block;
	margin-right: 0.286rem;
}

.grey-dot {
	height: 8px;
	width: 8px;
	background-color: #898989;
	border-radius: 50%;
	display: inline-block;
	margin-right: 0.286rem;
}

@-webkit-keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
/*
Global placeholder styles
*/
::-webkit-input-placeholder {
	font-style: italic;
	color: #c3c3c3 !important;
}
:-moz-placeholder {
	font-style: italic;
	color: #c3c3c3 !important;
}
::-moz-placeholder {
	font-style: italic;
	color: #c3c3c3 !important;
}
:-ms-input-placeholder {
	font-style: italic;
	color: #c3c3c3 !important;
}

