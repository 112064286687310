.healthy {
	color: #51b38c;
}

.warning {
	color: #e8b754;
}

.critical {
	color: #cf7272;
}

.neverSeen {
	color: #555555;
}

.add-button-contents {
	margin: 0 5px 0 5px;
	vertical-align: bottom;
}

.dropdownmenu {
	width: 189px;
	border-radius: 4px;
	box-shadow: 0 0 10px 0 rgba(92, 92, 92, 0.15);
}

.dropdownmenu-item {
	text-align: center;
	padding: 0.571em 1.714em !important;
}
.dropdownmenu-item:hover {
	background-color: #043b74 !important;
}

.gateway-cell:hover {
	cursor: pointer;
	text-decoration: underline;
}

.node-status:hover {
	cursor: pointer;
	text-decoration: underline;
}
/* .menu-text {
	width: 79px;
	height: 24px;
	font-family: Source Sans Pro;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: #47516f;
  } */
