.create-link {
	color: forestgreen;
}

.delete-button,
.update-link {
	color: peru;
}

.wrapper {
	padding-top: 2em;
	/*padding-bottom: 2.5rem;*/
}

.form-card {
	margin-top: 2.043rem;
	padding-bottom: 2em;
}

.purple {
	color: mediumslateblue;
}

.disabled-link {
	pointer-events: none;
}

.dropdown-link,
.dropdown-link:hover {
	text-decoration: none;
}

.dropdownmenu-item,
.dropdownmenu-item:hover {
	color: #043b74 !important;
}

.dropdownmenu-item:active {
	background-color: inherit !important;
}

.dropdownmenu {
	box-shadow: 0 0 10px 0 rgba(92, 92, 92, 0.15);
}

.location-item {
	list-style-type: none;
	border-radius: 4px;
	border: solid 1px #0a73b8;
	padding: 0.75rem;
	color: #0a73b8;
	margin-top: 0.5rem;
}

.locations-list {
	padding-left: 0 !important;
}

.clear-icon,
.down-icon,
.location-clear-icon {
	margin-left: 0.5em;
	cursor: pointer;
}

.clear-icon > path {
	fill: #555;
}

.clear-icon:hover > path {
	fill: #ed8347;
}

.location-clear-icon > path {
	fill: #0a73b8;
}
.location-clear-icon:hover > path {
	fill: #043b74;
}

.down-icon:hover > path {
	fill: #ed8347;
	stroke: #ed8347;
}

.custom-dropdown-placeholder {
	cursor: pointer !important;
}

.custom-dropdown-placeholder > div > div:not(.multi-select-dropdown) {
	color: #c3c3c3 !important;
}

.multi-select-dropdown {
	color: #2a68a8;
	padding: 0.964rem 1.643rem;
}

.multi-select-dropdown:hover {
	background-color: #0a73b8;
	color: #fff;
	cursor: pointer;
}

.role-disabled {
	background-color: rgb(242, 242, 242);
	cursor: not-allowed;
	color: rgb(204, 204, 204);
}

.role-disabled:hover {
	cursor: not-allowed;
}

.custom-input-search {
	line-height: inherit;
	/*width: calc(0ch + 5px);*/
	border: none;
	margin-left: 5px;
	background: transparent;
	font-size: smaller;
}

.custom-input-search {
	outline: 0;
}

ins {
	font-size: x-small;
	text-transform: uppercase;
	text-decoration: none;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(204, 204, 204);
	border-image: initial;
	border-radius: 2px;
	padding: 0px 3px;
	margin-left: 0.4em;
}

.alert-link {
	color: #fff !important;
	text-decoration: underline;
}

.placeholder-text {
	font-size: 16px;
	color: #c3c3c3;
	margin-top: 1.344rem;
}

.search-and-toggle {
	display: flex;
	flex-direction: column;
	padding: 0.75rem;
}

.dropdown-buttons {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.dropdown-buttons div {
	margin: 10px 0 0 0;
	font-weight: 600;
}
