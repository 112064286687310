.search {
	border-radius: 4px !important;
	border: solid 1px #c3c3c3;
	max-width: 17.125em !important;
	background-color: transparent !important;
	padding-left: 2px;
	height: 2.857em;
	transition: border 0.25s;
	margin: 0 4px;
}

.search-button {
	width: 2.857em;
	height: 2.857em;
}

.custom-input {
	flex-grow: 2 !important;
	border: none !important;
	background-color: transparent !important;
	border-radius: 20px !important;
	max-width: 12em !important;
	padding-top: 0.7rem;
	padding-left: 4px;
	height: 1.9em !important;
	box-shadow: none !important;
	margin-top: 0.2em;
}

.custom-input::placeholder {
	color: #c3c3c3 !important;
}

.custom-input:focus {
	outline: none !important;
}

.search-logo {
	margin-bottom: 0.2em;
}

.search-logo-focus > g > g {
	stroke: #47516f;
}

.custom-button {
	padding: 0 1.25em 0 0 !important;
	background-color: transparent !important;
	border: none !important;
	border-radius: 50px !important;
}

.search-keyword {
	background-color: white;
	padding: 0.7em 1.143em 0.7em 1.286em;
	border-radius: 30px;
	margin-right: 0.75em;
}

.search-keyword:first-child {
	margin-left: 1.156em;
}

.close-button {
	cursor: pointer;
	margin: 0 0 0 0.5em;
	padding: 0.2em 0.5em;
	border-radius: 50%;
}

.close-button:hover {
	background-color: rgba(32, 33, 36, 0.059);
}

.close-button > svg {
	margin-bottom: 0.2em;
}

.button-div {
	margin-bottom: 0.4em;
}

.auto-suggest-dropdown {
	cursor: pointer;
	padding: 0.5em;
}

.custom-dropdown {
	box-shadow: 0 0 10px 0 rgba(92, 92, 92, 0.3) !important;
}

/*.auto-suggest-dropdown:hover {*/
/*	background-color: #2a68a8;*/
/*	color: #ffffff;*/
/*}*/

.auto-suggest-dropdown:first-child:hover {
	border-radius: 4px 4px 0 0;
}

.auto-suggest-dropdown:last-child:hover {
	border-radius: 0 0 4px 4px;
}
