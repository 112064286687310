.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.row {
	height: 100%;
}

.main-row {
	background-color: #eaebf0;
}

.sidebar-layout {
	width: 64px;
}
.main-page-layout {
	width: calc(100% - 64px);
	overflow-x: hidden;
}

@media (min-width: 667px) {
	.sidebar-layout {
		width: 3.9%;
	}
	.main-page-layout {
		width: 96%;
	}
}
