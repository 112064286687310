.boxLayout {
	margin: 6em 6em 0 6em;
	padding-bottom: 2em;
}

.baseLayout {
	background-color: #f2f4f8;
	padding: 6em;
}

.deviceTitle {
	text-align: left;
}

.nodeLayout {
	border: 1px solid #2e2e2e;
	background-color: #f5ebd5;
}

.airconLayout {
	border: 1px solid #2e2e2e;
	padding: 8em;
	background-color: #a3d9e8;
}

.cardLayout {
	margin-top: 2em;
}

.cardBodyLayout {
	padding: 2em !important;
}
