.location-name {
	font-size: 35px;
	margin-top: 32px;
	margin-bottom: 20.5px !important;
	color: #043b74 !important;
}

.tab-style {
	cursor: pointer;
	color: #a6a6a6 !important;
}

.nav-link-active {
	color: #495057 !important;
	box-shadow: inset 0 -4px 0 -1px #3a435e;
}

.custom-nav-item {
	margin-right: 24px;
	font-size: 1.286em;
}
