.custom-header-text {
	font-size: 24px;
	padding: 24px;
	color: #ffffff !important;
	display: inline-block;
}

.custom-header {
	background-color: #043b74;
}

.custom-header > button {
	color: #ffff !important;
}

.custom-header .close-btn {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.modal-error-text {
	color: #cf7272;
	/* margin: 10px; */
}

.modal-info {
	margin-top: 10px;
	margin-bottom: 24px;
	line-height: 24px;
}

.modal-info > label {
	color: #a6a6a6;
}

.modal-info > input {
	color: #898989;
}

.custom-modal-body {
	height: 100vh;
}

.close-icon {
	float: right;
	margin: 1rem;
	cursor: pointer;
}
