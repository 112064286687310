.error {
	color: red;
}

.formComponent {
	margin-top: 30px;
}

.displayComponent {
	margin-top: 10px;
}

.react-datepicker__input-container {
	width: 100%;
}

.selected {
	background-color: #e5f1ff;
}

/* side panel */
.itemInfo {
	/* attempt at using variables, this will eventually be in a react design system */
	--unit-xs: 4px;
	--unit-s: 8px;
	--unit-m: 16px;
	--unit-l: 24px;
	background: white;
	padding: var(--unit-s);
}

@media (min-width: 992px) {
	.itemInfo {
		padding: var(--unit-s) var(--unit-m);
		/* hacky MVP for the new UI principles we want to implement, when we implement the design system we will refactor all existing layouts all together and these won't be needed anymore */
		/* hack for breaking out of global 3.5em margin on all layouts container */
		width: calc(100% + 3.5em);
		/* another hack for positioning the side panel, it will never be aligned this way! */
		margin-top: 126px;
	}
}

.itemInfo h2 {
	font-size: 26px;
	font-weight: normal;
	color: #757575; /* AA 4.5 contrast ratio */
	margin-bottom: 0.25em; /* scales with text */
}

.itemInfo h3 {
	font-size: 18px;
	font-weight: normal;
	color: #757575; /* AA 4.5 contrast ratio */
	margin-bottom: 0.25em; /* scales with text */
}

.itemInfo p {
	font-size: 14px;
	font-weight: normal;
}

.itemInfo dt {
	color: #757575; /* AA 4.5 contrast ratio */
	border-bottom: 1px solid #c3c3c3;
}

.itemInfo dd {
	font-size: 18px;
}

.itemInfo .smallText {
	font-size: 14px;
}

.update {
	margin-top: var(--unit-m);
	border-bottom: 1px solid #c3c3c3;
	padding-bottom: var(--unit-m);
}

.update dt,
.update dd {
	font-size: 14px;
	margin: 0;
	border-bottom: none;
}

.update dd {
	font-size: 12px;
}

.nodesList {
	padding-left: 0;
	list-style-type: none;
}

.nodesList li {
	font-size: 87.5%;
	color: #e83e8c;
	word-wrap: break-word;
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cancelButton {
	font-size: 12px !important; /* make smaller */
	margin-top: var(--unit-s);
	margin-bottom: var(--unit-s);
}

.toast {
	position: fixed; /* always on top of screen */
	top: 40px;
	right: 20px;
	min-width: 250px;
}

.toast .toast-body {
	background-color: #e5f1ff;
}

.updateLogLink {
	color: #d98b48;
	cursor: pointer;
	display: inline-block;
}

.updateLog {
	resize: none;
	font-size: 87.5%;
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	width: 100%;
	height: 300px;
	border: 1px solid #c3c3c3;
	overflow-y: scroll;
	background-color: #eaebf0;
}

.updateLog:focus {
	outline: none;
	border: 1px solid #000;
}
