.custom-navbar {
	background-color: #043b74;
	padding-left: 0 !important;
	width: 56px;
}

.custom-nav-item {
	width: 100%;
	border-bottom: 0.5px solid rgba(255, 255, 255, 0.19);
	padding: auto;
	vertical-align: center;
}

.custom-nav-item:hover {
	background-color: #234e7f;
	color: #fff !important;
}

.custom-dropdown {
	top: 0 !important;
	left: 100% !important;
	box-shadow: 0 0 8px 0 rgba(92, 92, 92, 0.15);
}

.custom-navlink {
	padding: 1rem !important;
}

.custom-dropdown-link {
	color: #5c5c5c !important;
	padding: 0.813em 1.5em !important;
}

.custom-dropdown-link:hover {
	color: #fff !important;
}

.custom-dropdown-link:hover {
	color: #ea6e28 !important;
	cursor: pointer;
}

.custom-dropdown-header {
	font-weight: bold !important;
	font-size: 14px !important;
	color: #5c5c5c !important;
}
