.custom-header-text {
	font-size: 18px;
	font-weight: 600;
	color: #272727 !important;
	display: inline-block;
}

.custom-header {
}

.custom-header > button {
	color: #ffff !important;
}

.custom-header .close-btn {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.modal-error-text {
	color: #cf7272;
	/* margin: 10px; */
}

.modal-info {
	margin-top: 10px;
	margin-bottom: 24px;
	line-height: 24px;
}

.modal-info > label {
	color: #a6a6a6;
}

.modal-info > input {
	color: #898989;
}

.custom-modal-body {
	height: 100vh;
}

.close-icon {
	float: right;
	margin: 1rem;
	cursor: pointer;
}

.modal-body > p {
	color: #555555;
	font-size: 16px;
}

.modal-body {
	margin-bottom: 1.5rem;
}

.modal-footer > button {
	float: right;
}

.cancel-button {
	margin-right: 0.5em;
	border: 1px solid #555555 !important;
}

.cancel-button:hover {
	background-color: #f7f7f7 !important;
}

.confirm-button {
	padding: 0.5em 1.143em !important;
	min-width: 128px;
}
