.custom-table {
	background-color: white;
	color: #555555 !important;
	border-radius: 4px;
}

.pagination-custom {
	cursor: pointer;
	margin-left: 1.4em;
	width: 1.5em;
	height: 1.5em;
	border-radius: 1px;
}

.pagination-custom:hover {
	background-color: #f6f6f6;
}

.active-custom {
	background-color: #3a435e;
}

.active-custom:hover {
	background-color: #3a435e !important;
}
.active-custom > a {
	color: #fff !important;
}

.link {
	width: inherit;
	height: inherit;
	color: #898989;
}

.link:hover {
	text-decoration: none;
}

.rotate-right {
	transform: rotate(270deg);
}

.rotate-left {
	transform: rotate(90deg);
}

.add-button {
	padding: 0.571rem 1.143rem;
	line-height: 1.6;
	white-space: nowrap;
}

p {
	font-weight: 300;
	font-size: 2.18em;
	margin-bottom: 0 !important;
	line-height: 1;
}

.row-custom {
	margin-top: 2.75em;
}

.custom-list {
	margin-top: 2.929em !important;
}

.custom-list > li {
	padding-left: 0 !important;
}

.table-header {
	cursor: pointer !important;
}

.filtered-text {
	font-size: 12px;
	margin: 0;
	font-weight: 500;
}

.filtered-list {
	background-color: #a29f9c;
	list-style-type: none;
	margin-right: 1em;
	cursor: pointer;
	border-radius: 2px;
	padding: 2px 8px;
	color: white;
	font-weight: 600;
}

.cancel-filtered-keyword {
	transform: scale(0.6);
}

tbody tr:hover {
	transition: background-color 0.3s ease;
}

tbody tr:hover {
	background-color: #f7f7f7;
}

.booleanFilter {
	padding: 0.5em 1.143em;
	margin-top: 6px;
}

.booleanFilter input {
	margin-right: 0.25em;
}

.drag-over {
	border-left: 1px solid #6b79a4;
}
