.container {
	margin: 2em 1em 0 1em;
}

.nav-link-active {
	color: #495057;
	background-color: #fff;
	border-color: #dee2e6 #dee2e6 #fff !important;
}

.tab-style {
	cursor: pointer;
}
