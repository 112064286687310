.RowContainer {
	display: flex;
}

.Rectangle {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex: 1 1 24px;
	border-radius: 4px;
	padding: 8px;
	background-color: #ffffff;
	margin: 0 4px 8px 4px;
}

@media only screen and (max-device-width: 890px) {
	.Rectangle {
		text-align: center;
	}
}

.Rectangle:first-child {
	margin-left: 0;
}

.status-label {
	font-family: Source Sans Pro;
	font-size: 14px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.14;
	letter-spacing: normal;
}

.Offline-Copy-2 {
	height: 16px;
	width: 10.357rem;
	color: #e27e7e;
}

.Online {
	height: 16px;
	width: 10.357rem;
	color: #4ac594;
}

.Never-Seen {
	height: 16px;
	width: 10.357rem;
	color: #858b9d;
}
.Total {
	color: #47516f;
}

.-copy {
	font-family: Source Sans Pro;
	font-size: 20px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #555555;
	display: inline-flex;
	align-items: center;
}

.online-number {
	color: #4ac594;
	font-weight: 600;
	margin: 0 auto;
}

.never-seen-number {
	color: #858b9d;
	font-weight: 600;
	margin: 0 auto;
}

.offline-number {
	color: #e27e7e;
	font-weight: 600;
	margin: 0 auto;
}

.container-width {
	width: 60%;
}

.selectedNodeStatus {
	border: 1px solid #043b74;
}
