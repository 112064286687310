/* Styles for create gateway */

.load-page-text {
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: center;
	color: #3a435e;
}

.gateway-success-rectangle {
	width: 333px;
	border-radius: 4px;
	background-color: #ffffff;
	display: block;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
}

.gateway-success-label {
	font-family: Source Sans Pro;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: center;
	font-size: 14px;
	font-weight: 600;
	line-height: 2.29;
	color: #47516f;
	margin-left: 24px;
	text-align: left;
}

.gateway-success-vars {
	font-family: Source Sans Pro;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: center;
	font-size: 14px;
	font-weight: normal;
	line-height: 2.29;
	color: #47516f;
	text-align: right;
	margin-right: 24px;
}

.download-button {
	border-radius: 0px 0px 4px;
	background-color: #ed8347;
	margin-left: 15px;
	margin-right: 15px;
	height: 40px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	color: #ffffff;
	font-family: Source Sans Pro;
	cursor: pointer;
}

.download-button:hover {
	background-color: #f69a66;
}
