.logo {
	width: 90px;
	height: 54.9px;
	margin-left: 2em;
}

.custom-navbar {
	background-color: white;
	padding: 0 !important;
	margin: 0 !important;
	box-shadow: 0 0 6px 0 rgba(92, 92, 92, 0.18);
}

.custom-button {
	padding: 1.6em 1.3em 1.9em 1.3em !important;
	text-align: center;
	border: none !important;
	border-bottom: 0.5px solid rgba(255, 255, 255, 0.19) !important;
	background-color: #043b74 !important;
	color: white !important;
	border-radius: 0 !important;
	font-size: 1rem !important;
}

.custom-button:hover {
	background-color: #234e7f !important;
}

.custom-button:focus {
	box-shadow: none !important;
}

.down-arrow {
	margin-left: 1.844em;
	margin-right: 1.5em;
	margin-bottom: 0.4em;
}

.custom-dropdown {
	box-shadow: 0 10px 10px 0 rgba(92, 92, 92, 0.08);
}

.username {
	font-size: 16px;
	padding: 1.7em 1.5em !important;
	color: #6d7278;
}

.username:hover {
	color: #47516f !important;
}
